import React from "react"
import SEO from "../components/seo"

export default () => (
  <div style={{ color: `black` }}>
    <h1>Yarbridge Holdings</h1>
    <p>Yarbridge Holdings is a diversified investment company with interests in Europe and Asia. </p>
  </div>
)



